import React, { useEffect } from 'react'

import styled from 'styled-components'
import {
  useLocation, useNavigate, useParams
} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment/moment'

import { SvgIcon } from '../ui/svg/SvgIcon'
import { appActions } from '../features/app/appSlice'

export const CertificatePage = ({ className = '' }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(appActions.setActiveTabbarTab('certificates'))
  }, [])

  const { id } = useParams()
  const { data } = useLocation().state

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <SCertificatePageBox className={className}>
      <STopRow>
        <SArrowIcon
          type='arrow'
          onClick={handleBack}
        />

        {data?.code?.certificate?.name}
      </STopRow>

      <SNumberTitle>
        Номер сертификата
      </SNumberTitle>

      <SNumber>
        {data?.code?.text}
      </SNumber>

      <SDescription>
        {
          data?.code?.certificate?.serial && (
            <>
              Серия {data?.code?.serial}

              <br /><br />
            </>
          )
        }


        Покупка от {moment(data?.updatedAt).format('DD.MM.yyyy')}

        <br /><br />

        {data?.code?.certificate?.description}
      </SDescription>
    </SCertificatePageBox>
  )
}

const SCertificatePageBox = styled.div`
    background: #212327;
    padding: 24px 16px 90px;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const STopRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    position: relative;
    width: 100%;
`

const SArrowIcon = styled(SvgIcon)`
    margin-right: 15px;
    transform: rotate(180deg);
    position: absolute;
    left: 0;
`

const SNumberTitle = styled.p`
    margin-top: 48px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: white;
`

const SNumber = styled.div`
    margin-top: 8px;
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: white;
    background: #000000;
    border-radius: 20px;
`

const SDescription = styled.div`
    margin-top: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: white;

`
